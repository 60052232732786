import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work',
  templateUrl: '../../utils/pages/building/index.html',
  styleUrls: ['../../utils/pages/building/style.css']
})
export class WorkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
